import React from "react"
import styles from "./share-options.module.scss"

import TwitterIcon from "../images/share-twitter.svg"

/* eslint-disable */
export default (props) => (
  <section>
    <a
      className={styles.shareOptions}
      href={`https://twitter.com/intent/tweet?via=okkotlin&text=${props.title}&url=https://${props.url}`}
      target="_blank"
      rel="noopener nofollow noreferrer"
    >
      <div className={styles.shareOption}>
        <p className={styles.shareOptionText}>Share this post on</p>
        <img className={styles.shareOptionImg} src={TwitterIcon} alt="Share on Twitter"/>
      </div>
    </a>
  </section>
)
