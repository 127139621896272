import React from "react"
import { graphql } from "gatsby"
import styles from "./blog-post.module.scss"

import Container from "../components/container"
import Metadata from "../components/metadata"
import Outline from "../components/article/outline"
import ShareOptions from "../components/article/share-options"
import ReadNext from "../components/article/read-next"
import Tag from "../components/article/tag"

export default ({ data, pageContext }) => {
  const post = data.markdownRemark
  const postUrl = data.site.siteMetadata.domain + pageContext.slug

  return (
    <Container>
      <Metadata
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt || post.excerpt}
        featuredImage={post.frontmatter.featuredImage}
      />

      <Outline headings={post.headings}/>

      <article>
        <header>
          <h1>{post.frontmatter.title}</h1>

          <section className={styles.postMetaSection}>
            <h4 className={styles.postMeta}>{post.frontmatter.date}</h4> <span className={styles.metaDivider}>/</span> <h4
            className={styles.postMeta}>{post.timeToRead} min read</h4> <span className={styles.metaDivider}>/</span>

            {post.frontmatter.tags.map(tag => (
              <Tag tag={tag}/>
            ))}
          </section>
        </header>

        <div style={{ marginTop: 40 + "px" }} dangerouslySetInnerHTML={{ __html: post.html }}/>
      </article>

      <ShareOptions url={postUrl} title={post.frontmatter.title}/>

      {pageContext.readNext &&
      <ReadNext readNext={pageContext.readNext}/>
      }
    </Container>
  )
}

export const query = graphql`
      query($slug: String!) {
      site {
      siteMetadata {
      domain
    }
    }
      markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        tags
        excerpt
        featuredImage
      }
      excerpt
      timeToRead
      headings(depth: h2) {
      value
    }
    }
    }
      `
