import React from "react"
import { Link } from "gatsby"
import styles from "./read-next.module.scss"

import IconHeading from "../heading/icon-heading"
import NextIcon from "../images/heading-next.svg"

export default (props) => {
  const post = props.readNext.node

  return (
    <section className={styles.readNextSection}>
      <IconHeading text="Read this next" image={NextIcon}/>

      <Link className={styles.readNextArticleLink} to={post.fields.slug}>
        <h3 className={styles.readNextArticleTitle}>{post.frontmatter.title}</h3>
        <p className={styles.readNextArticleExcerpt}>{post.frontmatter.excerpt || post.excerpt}</p>
      </Link>
    </section>
  )
}
